<template>
  <Panel :isActive="isSelected">
    <Fragment v-if="boundary">
      <PanelTitle :data="{ name: $t('map.general.huntingGround') }" :areaInHa="areaInHa()" @close="cancel" />

      <PanelContent>
        <div v-if="!boundary.editable">
          <div class="field buttons">
            <Button class="is-fullwidth is-light" @click="edit">
              <Icon name="icon icon-edit" />
              <span v-show="!isEmpty">{{ $t('general.edit') }} {{ $t('map.general.boundary') }}</span>
              <span v-show="isEmpty">{{ $t('general.draw') }} {{ $t('map.general.boundary') }}</span>
            </Button>
          </div>
        </div>

        <Fragment v-if="boundary.editable">
          <div class="field buttons">
            <Button
              type="primary"
              :disabled="isRemoving"
              :loading="isSaving"
              :title="$t('general.save')"
              @click="save">
            </Button>

            <Button
              :disabled="isLoading"
              :title="$t('general.cancel')"
              @click="cancel">
            </Button>

            <Button
              v-if="!isEmpty"
              type="danger"
              :loading="isRemoving"
              :disabled="isSaving"
              icon="icon-trash"
              @click="confirmRemove">
            </Button>
          </div>
        </Fragment>
      </PanelContent>
    </Fragment>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'

import SubAreaModel from '@/api/map/subareas/subAreaModel'

const STATE_DEFAULT = ''
const STATE_SAVING = 'saving'
const STATE_REMOVING = 'removing'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      original: {
        coordinates: []
      },
      state: STATE_DEFAULT,
      isEmpty: false,
      selectedSubArea: new SubAreaModel({})
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    isSaving () {
      return this.state === STATE_SAVING
    },

    isRemoving () {
      return this.state === STATE_REMOVING
    },

    isLoading () {
      return this.isSaving || this.isRemoving
    },

    isSelected () {
      return this.$store.getters['map/boundary/selected']
    },

    editable () {
      return this.boundary.editable
    }
  },

  watch: {
    editable (isEditable) {
      if (isEditable) {
        this.original.coordinates = this.boundary.coordinates // Save coordinates as backup if user cancels
      }
    },

    '$route' () {
      this.checkRoute()
    }
  },

  created () {
    eventManager.$on('boundary:delete', state => this.remove(state))
  },

  mounted () {
    this.checkRoute()
  },

  methods: {
    checkRoute () {
      const path = this.$route.path
      const isActive = path.startsWith('/area/') && path.includes('/boundary')

      this.$store.dispatch('map/boundary/select', isActive)

      if (isActive) {
        this.edit()
      }
    },

    close () {
      this.state = ''
      this.$store.commit('map/boundary/select', false)
      this.$router.push(`/area/${this.huntAreaId}/map`)
      this.boundary.editable = false
    },

    cancel () {
      this.boundary.coordinates = this.original.coordinates
      this.close()
    },

    edit () {
      this.boundary.editable = true
      this.isEmpty = this.boundary.coordinates === null || this.boundary.coordinates.length === 0
    },

    async save () {
      this.state = STATE_SAVING

      try {
        await this.$store.dispatch('map/boundary/update', this.huntAreaId)
        this.original.coordinates = []
        this.close()
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    async confirmRemove () {
      const response = await this.$dialog.confirm({
        title: this.$t('general.remove'),
        message: this.$t('general.reallyRemove', { name: this.$t('map.general.boundary') }),
        cancel: this.$t('general.cancel'),
        ok: this.$t('general.remove')
      })

      if (response.ok) {
        try {
          await this.remove()
          this.close()
        } catch (error) {
          this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
        }
      }
    },

    async remove () {
      const currentCoordinates = this.boundary.coordinates.slice()

      this.state = STATE_REMOVING

      try {
        this.boundary.coordinates = []
        await this.$store.dispatch('map/boundary/update', this.huntAreaId)
      } catch (error) {
        this.boundary.coordinates = currentCoordinates
        throw error
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    areaInHa () {
      return Number((this.boundary.computeArea() / 10000).toFixed(0))
    }
  }
}
</script>

<style scoped>
.is-boundary {
  background-color: #66f;
  color: white;
}
</style>
